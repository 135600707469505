<template>
  <component :is="bankData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="bankData === undefined">
      <h4 class="alert-heading">{{ $t("message.errorLoadingData") }}</h4>
      <div class="alert-body">
        {{ $t("message.banks.bankNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-bank-list' }">
          {{ $t("message.banks.bankList") }}
        </b-link>
        {{ $t("message.banks.forOtherBanks") }}
      </div>
    </b-alert>

    <template v-if="bankData">
      <div>
        <b-card>
          <h2 class="mb-3">{{ $t("message.banks.edit") }}</h2>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.name") }}
                </template>
                <b-form-input v-model="bankData.name" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankName") }}
                </template>
                <b-form-input v-model="bankData.bankName" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankNumber") }}
                </template>
                <b-form-input v-model="bankData.bankNumber" type="number" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankAgency") }}
                </template>
                <b-form-input v-model="bankData.bankAgency" type="number" />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label-for="typeBank">
                <template v-slot:label>
                  {{ $t("message.banks.bankAccount") }}
                </template>
                <b-form-input v-model="bankData.bankAccount" type="number" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>

    <div class="text-right">
      <b-button
        @click="updateBanks()"
        variant="primary"
        :to="{ name: 'apps-bank-list' }"
      >
        {{ $t("message.buttons.save") }}
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import dateInputEdit from "@/@core/utils/dateInputEdit";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    flatPickr,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      activeDate: false,
      activeDay: false,

      typeOptions: [
        { label: "Isolado", value: "isolate" },
        { label: "Recorrente", value: "recurrent" },
      ],

      flatConfig: { altFormat: "d/m/Y", altInput: true, format: "d/m/Y" },

      flatConfigDay: {
        dateFormat: "j",
      },

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      userData: store.state.user.userData,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInputEdit(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInputEdit(this.$i18n.locale);
    }
  },

  methods: {
    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },

    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    updateBanks() {
      axios({
        method: "put",
        url: `${URL_API}bank/${this.bankData.id}`,
        headers: getHeader(this.userData),
        data: this.bankData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updatedB"),
          },
        });
      });
    },

    selectedType() {
      if (this.bankData.type === "isolate") {
        this.activeDate = true;
        this.activeDay = false;
      } else {
        this.activeDate = false;
        this.activeDay = true;
      }
    },
  },
  setup() {
    const bankData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}bank/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        bankData.value = response.data;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          bankData.value = undefined;
        }
      });

    return {
      bankData,
    };
  },
};
</script>

<style>
.mtn {
  margin-top: -0.7rem;
}
</style>
